@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./Gotham-Medium.otf) format('opentype');
}

html, body, #root {
  background-color: #121212 !important;
  font-family: Gotham, Arial, serif;
  overflow-x: hidden;
}
